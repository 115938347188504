import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'swipe',
  ];

  connect() {
    this.swiper = new Swiper(this.swipeTarget, {
      loop: true,
      slidesPerView: 2,
      freeMode: true,
      loopFillGroupWithBlank: true,
      spaceBetween: 20,
      speed: 5000,
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
      },
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        640: {
          slidesPerView: 7,
          spaceBetween: 80,
        },
      },
    });
  }
}
