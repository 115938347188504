import { Controller } from 'stimulus';

export default class extends Controller {
  close() {
    const win = window.open("","_self"); /* url = "" or "about:blank"; target="_self" */
    win.close();
  }

  back() {
    window.history.back();
  }

  redirectToEdcast() {
    if(window.location.href.includes('https://iob.ie/'))
      window.location.href = 'https://iob.edcast.eu/'
    else
      window.location.href = 'https://iobsandbox.edcast.eu/'
  }
}
