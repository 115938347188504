import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'iFrame',
  ];

  connect() {
    this.iFrameTargets.forEach((iFrame) => {
      iFrame.onload = () => {
        iFrame.dataset.loaded = 'true';
      };
    });
    this.iFramesLoaded();
  }

  iFramesLoaded() {
    const controller = this;

    setTimeout(() => {
      const iframes = document.querySelectorAll('.logout-frame');
      let count = 0;
      for (let i = 0; i < iframes.length; i++) {
        if (iframes[i].getAttribute('data-loaded') === 'true') {
          count++
        }
      }
      if (count === iframes.length) {
        window.open(this.logoutUrl(),'_blank');
        window.location = '/users/sign_out';
      } else {
        controller.iFramesLoaded();
      }
    }, 2000);
  }

  logoutUrl(){
    const inProd = window.location.href.match('https://iob.ie/');

    const prodLogout = 'https://iob.edcast.eu/log_out';
    const stagingLogout = 'https://iobsandbox.edcast.eu/log_out';

    return inProd ? prodLogout : stagingLogout
  }
}
